.container-wrapper {
  margin: 0 auto;
  max-width: 1920px;
  min-width: 320px;
  padding-right: 3vw;
  padding-left: 3vw;
}
.container {
  margin: 0 auto;
}
.txt-center {
  text-align: center;
}

.title {
  font-weight: 100;
  padding: 10px;
  text-align: center;
}

h1 {
  font-family: Arial,sans-serif;
  font-size: 32px;
  line-height: 1.25em;
  letter-spacing: 0em;
  font-family: calluna;
  font-weight: 400;
  font-style: normal;
  font-size: 51px;
  line-height: 1.2em;
  letter-spacing: -.01em;
  text-transform: none;
  color: #081b33;
}
p {
  margin-top: 1.25em;
  margin-bottom: 1.6em;
  font-family: 'Jost', sans-serif;
}
label {
  -webkit-text-size-adjust: 100%;
  --rem: 16;
  color: rgba(8,27,51,.8);
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: .02em;
  text-rendering: optimizeLegibility;
  word-wrap: break-word;
  line-height: 1.68em;
  margin-bottom: 0;
  font-size: 13px;
  margin-top: 0;
}
a, a:active, a:hover {
  color: #666;
  text-decoration: none;
  font-weight: 500;
}
a:hover {
  color: #333;
  text-decoration: underline;
}
.block-img {
  max-width: none;
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
}


@media screen and (max-width: 768px) {
  .container {
    overflow: auto;
  }
   .column {
     min-width: 100%;
     width: 100%;
     margin: 10px 0;
  }
}

.App {
  font-family: Arial,sans-serif;
}

.App-logo {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 300px;
}

.App-header {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.full-image {
  width: 100%;
}
.city-wrapper {
  max-height: 400px;
  overflow: hidden;
  position: relative;
  min-height: 300px;
  background-position: center center;
  background: url('./images/minicity.jpg');
  background-position: center center;
  margin: 70px 0;
}
.btn {
  -webkit-text-size-adjust: 100%;
  --rem: 16;
  text-rendering: optimizeLegibility;
  background: transparent;
  display: inline-block;
  width: auto;
  height: auto;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  line-height: normal;
  padding: 21px 34px;
  font-family: 'Jost', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: .1em;
  border-color: #081b33;
  border-width: 2px;
  border-style: solid;
  background-color: transparent;
  transition: 0.1s background-color linear, 0.1s color linear;
  color: #081b33;
  border-radius: 300px;
}
hr{ 
    height: 1px;
    background-color: #bbbbbb;
    border: none;
}
footer {
  border-top: 1px solid #bbbbbb;
  border-bottom: 1px solid #bbbbbb;
  padding: 20px;
  margin-top: 100px;
  margin-bottom: 10px;
  text-align: right;
}